.logoContainer {
  display: flex !important;
  justify-content: center;
  align-items: center;
  min-height: 155px;
}

.logoImage {
  max-height: 120px !important;
  max-width: 120px !important;
  transition: transform 0.3s ease-in-out;
}

.logoImage:hover {
  transform: scale(1.15); /* Scale the image by 10% on hover */
}

.greyscale {
  /* filter: grayscale(100%);
  transition: filter 0.3s ease-in-out; */
}

.greyscale:hover {
  /* filter: grayscale(0%); */
}